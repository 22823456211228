import cx from "classnames";
import Select from "../Select";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { searchIcon, trash } from "../SVG";
import { useDeleteResource } from "../../context/delete-resource-context";
import { useDataTable } from "./useDataTable";
import { Button } from "../primitives/Button";
import { useTranslation } from "react-i18next";

export function DataTable({
  id,
  fetcher,
  className,
  columns,
  handlers,
  primaryKey = "id",
  children,
  deleteResourceType = "",
  tableClassNames,
  omitDeleteAction = false,
  beforeDeleteBegin = (row) => row,
  onLoadMore = null,
  rowWrapper = null,
  data = null,
  loading = false,
  loadingMore = false,
  hasMore = false,
  searchEnabled = true,
}) {
  const {
    rows: fetchedRows,
    error,
    isLoading: isLoadingFromFetch,
    sortyByColumns,
    afterDelete,
    searchValue,
    handleOnSearchChange,
    sortByValue,
    handleSortByOnChange,
  } = useDataTable({
    id,
    fetcher,
    columns,
    primaryKey,
  });

  const rows = data || fetchedRows;
  const isLoading = loading || isLoadingFromFetch;

  const deleteResource = useDeleteResource();
  const { t } = useTranslation();

  const safeRows = Array.isArray(rows) ? rows?.filter(row => row) : [];
  /**
   * RowWrapper is a function that takes a child and returns a child. It is used to wrap the row content.
   * It is useful when you want to pass some data to the whole content.
   */
  const RowWrapper = rowWrapper || (({ children }) => children({}));
  return (
    <>
      <div className="deviceBox__header">
        {children}
        <div className="deviceBox__header-more">
          {searchEnabled && <div className="input icon">
            <input
              type="text"
              value={searchValue}
              onChange={handleOnSearchChange}
              placeholder={t("Search here...")}
            />
            {searchIcon}
          </div>}
          {sortyByColumns.length ? (
            <Select
              keyInput="dataIndex"
              list={sortyByColumns}
              selected={
                sortyByColumns.filter(
                  (column) => column["dataIndex"] === sortByValue
                )?.[0]
              }
              onChange={handleSortByOnChange}
            />
          ) : null}
        </div>
      </div>
      <div className={cx("table", className)}>
        {error ? (
          <div>{t("Error fetching data! Please contact support.")}</div>
        ) : (
          <table className={cx("gen", tableClassNames)}>
            <thead>
              <tr>
                {columns?.map((column) => (
                  <th key={column.title}>{column.title}</th>
                ))}
                {!omitDeleteAction && <th />}
              </tr>
            </thead>
            <tbody>
              {safeRows?.map((row, index) => (
                <RowWrapper row={row} key={row[primaryKey]}>
                  {({ wrapperData }) => (
                    <tr onClick={() => handlers?.rowOnClick?.(row, index)}>
                      {columns?.map((column) => {
                        let renderer =
                          column.render ||
                          (() => column.dataIndex && row[column.dataIndex]);
                        return (
                          <td key={`${row[primaryKey]}-${column.title}`}>
                            <div
                              style={{
                                display: isLoading ? "none" : "block",
                              }}
                            >
                              {renderer(
                                row[column.dataIndex],
                                row,
                                index,
                                column,
                                wrapperData
                              )}
                            </div>
                            {isLoading && (
                              <span data-testid="table-in-loading-state">
                                <Skeleton />
                              </span>
                            )}
                          </td>
                        );
                      })}
                      {!omitDeleteAction && (
                        <td>
                          <div className="deviceTable__row">
                            <button
                              type="button"
                              className="delete"
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteResource.begin(deleteResourceType, {
                                  item: beforeDeleteBegin(row),
                                  afterDelete: () => afterDelete(row),
                                });
                              }}
                            >
                              {trash}
                            </button>
                          </div>
                        </td>
                      )}
                    </tr>
                  )}
                </RowWrapper>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {onLoadMore && !searchValue && hasMore && !loadingMore && (
        <Button
          text={t("Load More")}
          style={{ marginTop: "10px" }}
          onClick={() => onLoadMore()}
        />
      )}
    </>
  );
}
