import "./config/i18n";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { SplashScreen } from "./Base/SplashScreen";
import { AuthProvider, AuthConsumer } from "./context/auth-context";
import { Auth } from "./Base/Config/amplifyConfig";
import { Amplify } from "aws-amplify";
import awsExport from "./aws-exports";

Amplify.configure(awsExport);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <AuthProvider Auth={Auth}>
      <AuthConsumer>
        {(auth) => (!auth.isInitialized ? <SplashScreen /> : <App />)}
      </AuthConsumer>
    </AuthProvider>
  </BrowserRouter>
);


