import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { API } from "aws-amplify";
import { MqttOverWSProvider } from '@aws-amplify/pubsub/lib/Providers';
import useSWR from "swr";
import { Amplify, PubSub } from "aws-amplify";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const ActionType = {
  INITIALIZE: "INITIALIZE",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;


export const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve(),
  changePassword: () => Promise.resolve(),
  completeNewPassword: () => Promise.resolve(),
  updateUserAttributes: () => Promise.resolve(),
  verifyCurrentUserAttributeSubmit: () => Promise.resolve(),
});

const getUserData = () => {
  const apiName = "ThermonovaAPI";
  const path = "/users/me";
  return API.get(apiName, path, {});
};

export const AuthProvider = (props) => {
  const { children, Auth } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { data: userData } = useSWR("getUserData", getUserData);

  useEffect(() => {

    if (!state.isAuthenticated || !userData) {
      console.log("not authenticated or user data not loaded, skipping mqtt connection");
      return;
    }

    if (userData && userData.mqttConnectionOptions) {
      const {
        host,
        signature,
        authorizerName,
        tokenValue,
        authToken
      } = userData.mqttConnectionOptions;
      PubSub.removePluggable('MqttOverWSProvider');
      Amplify.addPluggable(
        new MqttOverWSProvider({
          aws_pubsub_endpoint: `wss://${host}/mqtt?x-amz-customauthorizer-name=${authorizerName}&x-amz-customauthorizer-signature=${signature}&token=${tokenValue}&authToken=${authToken}`,
          connectOptions: {
            clean: true,
            keepalive: 60,
            protocolVersion: 4,
          }
        })
      );
    }
  }, [state.isAuthenticated, userData]);

  useEffect(() => {
    const initialize = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true,
        });
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: true,
            user: {
              id: user.sub || user.attributes.sub,
              email: user.attributes.email,
              email_verified: user.attributes.email_verified,
              identityId: user.attributes["custom:IdentityID"],
              phone_number: user.attributes.phone_number,
              phone_number_verified: user.attributes.phone_number_verified,
            },
          },
        });
      } catch (error) {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let TEMP_COGNITO_USER;

  const login = async (email, password) => {
    const user = await Auth.signIn(email, password);

    if (user.challengeName) {
      TEMP_COGNITO_USER = user;
      return { challengeName: user.challengeName };
    }

    if (!user.attributes["custom:IdentityID"]) {
      const result = await Auth.currentUserInfo();
      await Auth.updateUserAttributes(user, {
        "custom:IdentityID": result.id,
      });
    }

    dispatch({
      type: ActionType.LOGIN,
      payload: {
        user: {
          id: user.sub || user.attributes.sub,
          email: user.attributes.email,
          email_verified: user.attributes.email_verified,
          identityId: user.attributes["custom:IdentityID"],
          phone_number: user.attributes.phone_number,
          phone_number_verified: user.attributes.phone_number_verified,
        },
      },
    });
  };

  const logout = async () => {
    await Auth.signOut();
    dispatch({
      type: ActionType.LOGOUT,
    });
  };

  const register = async (email, password) => {
    return await Auth.signUp({
      username: email,
      password,
      attributes: { email },
    });
  };

  const verifyCode = async (username, code) => {
    return await Auth.confirmSignUp(username, code);
  };

  const resendCode = async (username) => {
    await Auth.resendSignUp(username);
  };

  const passwordRecovery = async (username) => {
    await Auth.forgotPassword(username);
  };

  const passwordReset = async (username, code, newPassword) => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
  };

  const completeNewPassword = async (newPassword) => {
    await Auth.completeNewPassword(TEMP_COGNITO_USER, newPassword);
    const user = await Auth.currentAuthenticatedUser();
    dispatch({
      type: ActionType.LOGIN,
      payload: {
        user: {
          id: user.sub,
          email: user.attributes.email,
        },
      },
    });
    TEMP_COGNITO_USER = null;
  };

  const updateUserAttributes = async (attributes) => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, attributes);
    dispatch({
      type: ActionType.LOGIN,
      payload: {
        user: {
          ...state.user,
          ...attributes,
        },
      },
    });
  };

  const changePassword = async (oldPassword, newPassword) => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
  };

  const verifyCurrentUserAttributeSubmit = async (attribute, code) => {
    await Auth.verifyCurrentUserAttributeSubmit(attribute, code);
    const updatedUser = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });
    dispatch({
      type: ActionType.LOGIN,
      payload: {
        user: {
          id: updatedUser.sub,
          email: updatedUser.attributes.email,
          email_verified: updatedUser.attributes.email_verified,
        },
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset,
        changePassword,
        completeNewPassword,
        updateUserAttributes,
        verifyCurrentUserAttributeSubmit,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
