import React from "react";
import { DataTable } from "../../../../Base/DataTable";
import { errorIcon, tickIcon } from "../../../../Base/SVG";
import { useTranslation } from "react-i18next";
import { useActiveAlarms, useResolvedAlarms } from "./useAlarmLogs";
import "./Logs.scss";
const buildColumns = (t) => {
  return [
    {
      dataIndex: "Name",
      title: t("Error"),
    },
    {
      dataIndex: "CreatedTimestamp",
      title: t("Date"),
      render: (_, row) => {
        if (!row?.CreatedTimestamp) return null;
        const date = new Date(row.CreatedTimestamp).toLocaleDateString();
        return <span>{date}</span>;
      },
    },
    {
      dataIndex: "CreatedTimestamp",
      title: t("Time"),
      render: (value, row) => {
        const dateValue = row.Active === "False" ? row.ResolvedTimestamp : value;
        if (!dateValue) return null;
        const date = new Date(dateValue);
        let time = date.toLocaleTimeString();
        return <span>{time}</span>;
      },
    },
    {
      dataIndex: "Active",
      title: t("Status"),
      render: (status) => {
        return status === "True" ? (
          <span className="logError">{errorIcon}</span>
        ) : (
          <span className="logActive">{tickIcon}</span>
        );
      },
    },
  ];
};

export default function Logs({ id }) {
  const { t } = useTranslation();
  const { activeAlarms, isLoading: isLoadingActive } = useActiveAlarms({
    serialnumber: id,
  });
  const {
    resolvedAlarms,
    isLoading: isLoadingResolved,
    isLoadingMore,
    loadMoreResolvedAlarmLogs,
    hasMoreResolvedLogs,
  } = useResolvedAlarms({
    serialnumber: id,
  });

  // Combine active and resolved alarms
  const allAlarms = React.useMemo(() => {
    if (!activeAlarms && !resolvedAlarms) return [];
    
    return [
      ...(activeAlarms || []).map(alarm => ({ 
        ...alarm, 
        Active: "True",
        id: alarm.id || `active-${alarm.CreatedTimestamp}` 
      })),
      ...(resolvedAlarms || []).map(alarm => ({ 
        ...alarm, 
        Active: "False",
        id: alarm.id || `resolved-${alarm.CreatedTimestamp}`
      }))
    ];
  }, [activeAlarms, resolvedAlarms]);

  const isLoading = isLoadingActive || isLoadingResolved;

  return (
    <div className="logs-container">
      <DataTable
        id={`device-logs-${id}`}
        primaryKey="id"
        columns={buildColumns(t)}
        className="deviceTable"
        tableClassNames="logs"
        data={allAlarms}
        loading={isLoading}
        loadingMore={isLoadingMore}
        hasMore={hasMoreResolvedLogs}
        onLoadMore={loadMoreResolvedAlarmLogs}
        omitDeleteAction
        searchEnabled={false}
      >
        <div className="deviceBox__header-more">
          <h3>{t("Logs")}</h3>
        </div>
      </DataTable>
    </div>
  );
}
