import setupAxios from "./setup-axios";

class Api {
  constructor(_axios) {
    this.axios = _axios;
  }

  async getHeatpumps() {
    return this.axios.get("/heatpumps");
  }

  async getHeatpump(serialnumber) {
    return this.axios.get(`/HP?Serialnumber=${serialnumber}`);
  }

  async getLogs({ Serialnumber, lang, length }) {
    return this.axios.get("/HP/alarmlog", {
      params: {
        Serialnumber,
        target: "app",
        lang,
        length,
      },
    });
  }

  async addHeatpump(serialnumber, moreData = {}) {
    return this.axios.post("/users/user/heatpump", {
      serialnumber,
      ...moreData,
    });
  }

  async changeOwner(serialnumber, mail) {
    const response = await this.axios.put("/HP/owner", { serialnumber, mail });
    const message = response.data.msg;
    let error;
    switch (message) {
      case "Owner changed":
        return { success: true, message };
      case "No access":
        error = { success: false, message };
        break;
      default:
        error = { success: false, message: "Unknown error" };
        break;
    }
    if (error) {
      throw error;
    }
  }

  async getHeatpumpUsers(Serialnumber) {
    return this.axios.get("/HP/access", {
      params: { Serialnumber },
    });
  }

  async revokeUserAccess(serialnumber, mail) {
    const response = await this.axios.delete("/users/user/heatpump", {
      data: { serialnumber, mail },
    });
    const message = response.data.msg;
    let error;
    switch (message) {
      case "User access revoked":
        return { success: true, message };
      case "Owner can not be removed":
        error = { success: false, message };
        break;
      case "User has no access to that heatpump":
        error = { success: false, message };
        break;
      case "No rights":
        error = { success: false, message: "No rights to remove access." };
        break;
      default:
        error = { success: false, message: "Unknown error" };
        break;
    }
    if (error) {
      throw error;
    }
  }

  async getNotificationPreferences() {
    return await this.axios.get("/users/user/notification-preference");
  }

  async updateNotificationPreferences({ Serialnumber, ...preferences }) {
    return await this.axios.patch("/users/user/notification-preference", {
      Serialnumber,
      ...preferences,
    });
  }

  async getActiveAlarms(serialnumber) {
    if (!serialnumber) throw new Error('Serialnumber is required');
    return this.axios.get(`/HP/alarmlog/active`, {
      params: { 
        Serialnumber: serialnumber 
      }
    });
  }

  async getResolvedAlarms(serialnumber, resolvedLastEvaluatedKey) {
    if (!serialnumber) throw new Error('Serialnumber is required');
    return this.axios.get(`/HP/alarmlog/resolved`, {
      params: { 
        length: 5,
        Serialnumber: serialnumber,
        ...(resolvedLastEvaluatedKey && { resolvedLastEvaluatedKey })
      }
    });
  }
}

const getApi = (function () {
  let api = null;
  return {
    instance: function () {
      if (!api) {
        api = new Api(setupAxios());
        return api;
      }
      return api;
    },
  };
})();
export { getApi };
